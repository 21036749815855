<template>
    <div
        class="timeline-comment-input"
        :class="{
            'is-empty': !currentValue,
            'is-disabled': disabled
        }"
    >
        <el-popover
            placement="left"
            trigger="click"
            popper-class="mark-popper u-mw-none"
            @after-enter="focusOnUsersSelect('usersSelect')"
        >
            <label
                slot="reference"
                class="el-button el-button--default el-button--medium is-round -attach"
                :class="{ 'is-disabled': disabled }"
            >
                <el-badge
                    v-if="mentions.length > 0"
                    :value="mentions.length"
                    class="item"
                >
                    <el-tooltip content="Упомянуть сотрудника в чате">
                        <iconify-icon icon="fa6-solid:users" />
                    </el-tooltip>
                </el-badge>
                <el-tooltip
                    v-else
                    content="Упомянуть сотрудника в чате"
                >
                    <iconify-icon icon="fa6-solid:users" />
                </el-tooltip>
            </label>
            <ui-remote-select-nested
                ref="usersSelect"
                v-model="mentions"
                multiple
                :fetch-items="fetchUsers"
                label-key="fullName"
            />
        </el-popover>

        <label
            class="el-button el-button--default el-button--medium is-round -attach"
            :class="{ 'is-disabled': disabled }"
        >
            <input
                type="file"
                multiple
                :disabled="disabled"
                @change="handleFileInputChange"
            >
            <iconify-icon icon="fa6-solid:paperclip" />
        </label>
        <el-input
            v-model="currentValue"
            :autosize="{ minRows: 1, maxRows: 10 }"
            class="timeline-comment-input__control"
            placeholder="Написать сообщение..."
            type="textarea"
            :disabled="disabled"
            @paste.native="handlePaste"
            @keydown.enter.exact.prevent.native
            @keyup.enter.exact.native="handleSend"
        />
        <el-button
            v-show="!sending"
            class="-send"
            round
            :disabled="disabled"
            @click="handleSend"
        >
            <iconify-icon icon="fa6-solid:paper-plane" />
        </el-button>

        <ui-dialog
            :visible.sync="dialogVisible"
            width="30%"
        >
            <ui-timeline-file
                v-for="(file, index) in fileList"
                :key="index"
                :name="file.name"
                :size="file.size"
                deletable
                @delete="handleFileDelete(file)"
            />

            <div class="timeline-comment-input__input-wrapper">
                <el-popover
                    placement="left"
                    trigger="click"
                    popper-class="mark-popper u-mw-none"
                    @after-enter="focusOnUsersSelect('usersSelectInDialog')"
                >
                    <label
                        slot="reference"
                        style="background-color: #fff"
                        class="el-button el-button--default el-button--medium is-round -attach"
                        :class="{ 'is-disabled': disabled }"
                    >
                        <el-badge
                            v-if="mentions.length > 0"
                            :value="mentions.length"
                            class="item"
                        >
                            <el-tooltip content="Упомянуть сотрудника в чате">
                                <iconify-icon icon="fa6-solid:users" />
                            </el-tooltip>
                        </el-badge>
                        <el-tooltip
                            v-else
                            content="Упомянуть сотрудника в чате"
                        >
                            <iconify-icon icon="fa6-solid:users" />
                        </el-tooltip>
                    </label>
                    <ui-remote-select-nested
                        ref="usersSelectInDialog"
                        v-model="mentions"
                        multiple
                        :fetch-items="fetchUsers"
                        label-key="fullName"
                    />
                </el-popover>
                <el-input
                    ref="dialogCaption"
                    v-model="dialogCaption"
                    placeholder="Написать сообщение..."
                    class="u-mt-sm"
                    :disabled="disabled"
                    @paste.native="handlePaste"
                />
            </div>

            <template slot="footer">
                <el-button
                    type="flat"
                    @click="dialogVisible = false"
                >
                    Отмена
                </el-button>
                <el-button
                    type="primary"
                    :loading="sending"
                    :disabled="sending"
                    @click="handleSend"
                >
                    Отправить
                </el-button>
            </template>
        </ui-dialog>
    </div>
</template>

<script>
import FormatMixin from '@/mixins/format';
import { getMembers } from '@/api/business';

export default {
    name: 'UiTimelineCommentInput',

    mixins: [FormatMixin],

    provide () {
        return {
            elForm: {},
        };
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        maxFileSize: {
            type: Number,
            default: 20480,
        },

        sending: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            fileList: [],
            dialogVisible: false,
            dialogCaption: '',
            mentions: [],
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },
        businessId () {
            return this.$store.getters['business/currentId'];
        },
    },

    watch: {
        dialogVisible (value) {
            if (value) {
                this.dialogCaption = this.currentValue;
                this.currentValue = '';
                this.$nextTick(() => {
                    this.$refs.dialogCaption.focus();
                });
            } else {
                this.fileList = [];
            }
        },

        fileList (value) {
            if (value.length > 0) {
                this.dialogVisible = true;
            }
        },
    },

    methods: {
        focusOnUsersSelect (refSelect) {
            this.$refs[refSelect]?.$refs?.select?.$refs?.select?.focus();
        },

        async fetchUsers (query, cb) {
            const params = {
                businessId: this.businessId,
            };

            if (query) {
                params.search = query;
            }

            try {
                const data = await getMembers(params);
                cb(data['hydra:member'] || []);
            } catch (e) {
                this.$flashError(e);
            }
        },

        processFiles (files) {
            for (const file of files) {
                if (!this.validateFile(file)) {
                    continue;
                }

                this.fileList.push(file);
            }
        },

        validateFile (file) {
            const isValidFileSize = file.size / 1024 < this.maxFileSize;

            if (!isValidFileSize) {
                this.$message.error('Превышен максимально допустимый размер файла');
            }

            return isValidFileSize;
        },

        handleFileInputChange (event) {
            this.processFiles(event.target.files);
        },

        handlePaste (event) {
            if (event.clipboardData && event.clipboardData.files.length) {
                event.preventDefault();
                this.processFiles(event.clipboardData.files);
            }
        },

        handleFileDelete (file) {
            const index = this.fileList.findIndex(f => f === file);
            if (index >= 0) {
                this.fileList.splice(index, 1);
            }
            if (this.fileList.length === 0) {
                this.dialogVisible = false;
            }
        },

        async handleSend () {
            const message = this.dialogCaption || this.currentValue || '';

            if (message.trim() === '' && this.fileList.length === 0) {
                return;
            }

            this.$emit('send', {
                message,
                files: [...this.fileList],
                mentions: this.mentions,
            });
            this.mentions = [];
            this.dialogVisible = false;
            this.currentValue = '';
            this.dialogCaption = '';
        },
    },
};
</script>

<style lang="scss">
.timeline-comment-input {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 4px;
    border: $--border-base;
    border-radius: $--border-radius-base;
    background: $--color-white;

    .el-button {
        font-size: 16px;
        line-height: 30px;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        padding: 0;
        cursor: pointer;
        color: #888 !important;
        border-color: transparent !important;
        background: transparent !important;

        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not(.is-disabled):hover,
        &:not(.is-disabled):active {
            background: $--color-primary-light-9;
        }

        &.-send {
            transition: $--fade-transition;
            color: $--color-primary;
        }

        &.-attach {
            input[type="file"] {
                visibility: hidden;
                position: absolute;
                opacity: 0;
            }
        }
    }

    &__control {
        font-size: 13px;
        flex-grow: 1;

        .el-textarea__inner {
            font-size: inherit;
            line-height: 16px;
            margin: 0;
            padding: 8px 4px;
            resize: none;
            border: 0;
            background: transparent !important;
        }
    }

    &.is-empty {
        .el-button.-send {
            visibility: hidden;
            opacity: 0;
        }
    }

    &.is-disabled {
        border-color: $--border-color-lighter;
        background-color: $--disabled-fill-base;
    }

    &__input-wrapper {
        display: flex;
        align-items: flex-end;
    }
}
</style>
