<template>
    <div
        v-if="data"
        class="user-panel"
        :class="{ 'user-panel--impersonated' : $store.getters['auth/isImpersonated'] }"
    >
        <el-dropdown
            trigger="click"
            size="large"
            @command="handleCommand"
        >
            <div class="user-panel__link">
                <avatar
                    :username="fullName"
                    :size="36"
                    class="user-panel__avatar"
                />
            </div>

            <template #dropdown>
                <el-dropdown-menu>
                    <div class="user-panel__header">
                        <avatar
                            :username="fullName"
                            :size="40"
                            class="user-panel__header-avatar"
                        />
                        <div class="user-panel__header-info">
                            <div class="user-panel__username">
                                {{ fullName }}
                            </div>
                            <div class="user-panel__email">
                                {{ data.email }}
                            </div>
                        </div>
                    </div>
                    <el-dropdown-item command="settings">
                        Настройки аккаунта
                    </el-dropdown-item>
                    <el-dropdown-item command="logout">
                        Выйти
                    </el-dropdown-item>
                    <el-dropdown-item
                        v-if="$store.getters['auth/isImpersonated']"
                        command="stopImpersonation"
                        class="u-color-danger"
                        title="Отменить подмену пользователя"
                    >
                        Отменить авторизацию
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script>
import Avatar from 'vue-avatar';

export default {
    components: {
        Avatar
    },

    computed: {
        data () {
            return this.$store.getters.me;
        },

        fullName () {
            if (this.data) {
                return `${this.data.firstName} ${this.data.lastName}`.trim();
            }

            return '';
        }
    },

    methods: {
        async handleCommand (command) {
            const commands = {
                settings: async () => {
                    await this.$router.push({ name: 'ProfileManage' });
                },

                logout: async () => {
                    await this.$store.dispatch('auth/logout');
                },

                stopImpersonation: async () => {
                    await this.$store.dispatch('auth/stopImpersonation');
                }
            };

            if (typeof commands[command] === 'function') {
                await commands[command]();
            }
        }
    }
};
</script>

<style lang="scss">
.user-panel {
    &__link {
        cursor: pointer;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        padding: 6px 17px 12px;
        border-bottom: $--border-base;
    }

    &__header-avatar {
        margin-right: 12px;
    }

    &--impersonated &__avatar {
        border: 3px solid #fff;
        outline: 3px solid $--color-warning;
    }

    &__username {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
    }

    &__email {
        font-size: 13px;
    }
}
</style>
