<template>
    <div
        class="home-layout"
        :class="{
            'is-nav-drawer-show': isNavDrawerShow,
            'no-transitions': stopTransitions
        }"
    >
        <main class="home-layout__main">
            <navigation-drawer v-if="hasNavDrawer">
                <template #prepend>
                    <div class="home-layout__logo">
                        <ui-logo />
                    </div>
                </template>
                <div
                    v-if="me"
                    class="home-layout__title"
                >
                    {{ `${me.firstName} ${me.lastName}` }}
                </div>
                <context-menu logout-button />
            </navigation-drawer>

            <div class="home-layout__content">
                <div class="home-layout__page-header">
                    <portal-target
                        name="page-title"
                        class="page-title home-layout__page-title"
                    >
                        {{ routeTitle }}
                    </portal-target>

                    <portal-target
                        name="page-actions"
                        multiple
                        class="default-layout__page-actions"
                    />
                </div>

                <div class="default-layout__page-body">
                    <slot />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ContextMenu from '@/components/layout/ContextMenu';
import NavigationDrawer from '@/components/layout/NavigationDrawer';
import NavigationDrawerMixin from '@/mixins/navigation-drawer';
import { getRouteTitle } from '@/utils/route';

export default {
    components: {
        ContextMenu,
        NavigationDrawer,
    },

    mixins: [NavigationDrawerMixin],

    props: {
        mode: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        me () {
            return this.$store.getters.me;
        },
        routeTitle () {
            return getRouteTitle(this.$route);
        }
    },
};
</script>
