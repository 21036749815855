import { createComment, getTimelines } from '@/api/timeline';

export default {
    namespaced: true,
    state: {
        timeline: [],
        isSending: false,
        isAllLoaded: false,
        isLoading: false,
        entities: [],
        fetchTimelineError: null,
        sendCommentError: null,
    },
    getters: {
        timeline: state => state.timeline,

        isSending: state => state.isSending,

        isAllLoaded: state => state.isAllLoaded,

        isLoading: state => state.isLoading,

        entities: state => state.entities,

        lastSort: state => Math.min(...state.timeline.map(item => item.sort)),
    },
    mutations: {
        FETCH_TIMELINE_START (state) {
            state.isLoading = true;
        },
        FETCH_TIMELINE_SUCCESS (state, { loadMore, data }) {
            if (!loadMore) {
                state.timeline = data['hydra:member'] ?? [];
            } else {
                state.timeline.push(...(data['hydra:member'] ?? []));
            }
            state.isAllLoaded = !data?.['hydra:view']?.['hydra:next'];
            state.isLoading = false;
            state.fetchTimelineError = null;
        },
        FETCH_TIMELINE_ERROR (state, error) {
            state.fetchTimelineError = error;
            state.isLoading = false;
        },

        SEND_COMMENT_START (state) {
            state.isSending = true;
        },

        SEND_COMMENT_SUCCESS (state, data) {
            state.timeline.unshift(data);
            state.sendCommentError = null;
            state.isSending = false;
        },
        SEND_COMMENT_ERROR (state, error) {
            state.sendCommentError = error;
            state.isSending = false;
        },

        RESET_TIMELINE (state) {
            state.timeline = [];
            state.isSending = false;
            state.isAllLoaded = false;
            state.isLoading = false;
            state.entities = [];
            state.fetchTimelineError = null;
            state.sendCommentError = null;
        },

        SET_ENTITIES (state, { append = false, entities }) {
            const arr = Array.isArray(entities) ? entities : [entities];

            if (!append) {
                state.entities = arr;
            } else {
                state.entities.push(...arr.filter(item => !state.entities.includes(item)));
            }
        },
    },
    actions: {
        async fetchTimeline ({ commit, state }, { loadMore = false, params }) {
            commit('FETCH_TIMELINE_START');
            try {
                if (params.entityId) {
                    commit('SET_ENTITIES', { entities: params.entityId });
                } else {
                    params.entityId = state.entities;
                }

                const data = await getTimelines(params);
                commit('FETCH_TIMELINE_SUCCESS', { data, loadMore });
                return data;
            } catch (e) {
                console.error(e);
                commit('FETCH_TIMELINE_ERROR', e);
                return null;
            }
        },
        async sendComment ({ commit }, { data }) {
            commit('SEND_COMMENT_START');
            try {
                const entry = await createComment(data);
                commit('SEND_COMMENT_SUCCESS', entry);
            } catch (e) {
                console.error(e);
                commit('SEND_COMMENT_ERROR', e);
                return null;
            }
        },
        setEntities ({ commit }, { entities, append = false }) {
            commit('SET_ENTITIES', { entities, append });
        },
        reset ({ commit }) {
            commit('RESET_TIMELINE');
        },
    },
};
