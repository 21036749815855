/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    VAT: 'НДС {rate}%',
    account: 'Счет',
    accountCashbox: 'Счет/Касса',
    accounted: 'Зачесть',
    accountingPeriod: 'Период учета',
    accounts: 'Счета',
    accountsAndDocuments: 'Счета, документы',
    accountsGroup: 'Группа счетов',
    accountsWithPlannedPayments: 'Счета с запланированными оплатами',
    accrued: 'Начислено',
    actions: 'Действия',
    activity: 'Активность',
    activityTypes: 'Виды деятельности',
    actualAddress: 'Фактический адрес',
    advance: 'Аванс',
    all: 'Все',
    amountExpenses: 'Сумма затрат',
    apiKey: 'API-ключ',
    apiSecret: 'Секретный ключ',
    applicant: 'Заявитель',
    approvalStage: 'Этап согласования',
    approve: 'Утверждение',
    approver: 'Согласователь',
    approvingFormat: 'Формат согласования',
    assessment: 'Начисление',
    assessmentCategory: 'Начисление/удержание',
    assessmentMonth: 'Месяц начисления',
    assessments: 'Начисления и удержания',
    avgSalary: 'Средний заработок',
    balance: 'Баланс',
    balanceOnEnd: 'Остаток на конец',
    balanceOnStart: 'Остаток на начало',
    bank: 'Банк',
    bankCard: 'Банковская карта',
    bankCardNumber: 'Номер банковской карты',
    bankName: 'Наименование банка',
    bankRequisiteType: 'Тип банковского реквизита',
    before: 'до',
    bik: 'БИК',
    birthday: 'Дата рождения',
    bonuses: 'Бонусы',
    business: 'Бизнес',
    businessFillTimesheetDaily: 'Проставлять явку в табеле в начале рабочего дня',
    businessId: 'Идентификатор бизнеса',
    businessLinkProductionDepartmentsWithActivities: 'Связывать отдел Производство с Направлением',
    businessOwnership: 'Владение бизнесом',
    businessRequisite: 'Реквизит бизнеса',
    businessSlug: 'Код бизнеса',
    buyer: 'Покупатель',
    byPaymentOrder: 'по банку п.п. №',
    calculateSalaryByPeriod: 'Рассчитать зарплату за {period}',
    calculated: 'Рассчитывается',
    calculation: 'Расчет',
    calculationType: 'Вид расчета',
    canceled: 'Аннулирован',
    cashBox: 'Касса',
    categories: 'Статьи',
    categoryAnalytics: 'Аналитика статьи',
    changeBankRequisites: 'Изменить реквизиты',
    changeData: 'Изменить данные',
    changePeriodMoney: 'Изменение денег за период',
    changesByPeriod: 'Изменения за период',
    chatId: 'Идентификатор чата',
    claim: 'Заявка',
    claimCreation: 'Создание заявки',
    color: 'Цвет',
    comment: 'Комментарий',
    commentToAccount: 'Комментарий к счету',
    compensationDays: 'Дней компенсации',
    compensationPeriod: 'Период компенсации',
    completed: 'Завершено',
    condition: 'Условие',
    content: 'Содержание',
    contractor: 'Контрагент',
    contractorRequisite: 'Реквизит контрагента',
    correspondentAccount: 'Корр. счет',
    create: 'Добавление',
    currency: 'Валюта',
    dataForApproval: 'Данные для согласования заявки',
    date: 'Дата',
    day: 'День',
    dayOffShort: 'Вых',
    days: 'Дни',
    daysGenitive: 'Дней',
    daysLeft: 'Осталось дней',
    daysShort: 'дн.',
    deal: 'Сделка',
    dealSum: 'Сумма сделки',
    debtWithPaid: 'Долг/Оплачено',
    delete: 'Удаление',
    department: 'Отдел',
    departments: 'Отделы',
    description: 'Описание',
    details: 'Детали',
    direction: 'Направление',
    directions: 'Направления',
    dismissedAt: 'Дата увольнения',
    dividendAllocation: 'Распределение дивидендов',
    document: 'Документ',
    documentDate: 'Дата документа',
    documentNumber: 'Номер документа',
    documentType: 'Тип документа',
    ebitda: 'Операционная прибыль (EBITDA)',
    edit: 'Редактирование',
    effectiveAt: 'Дата применения',
    email: 'Электронная почта',
    employee: 'Сотрудник',
    employees: 'Сотрудники',
    employment: 'Вид занятости',
    endDebt: 'Долг на конец',
    endPeriodMoney: 'Денег на конец периода',
    enteredManually: 'Вводится вручную',
    expenses: 'Затраты',
    fact: 'Факт',
    fio: 'ФИО',
    firstName: 'Имя',
    from: 'от',
    fullName: 'Полное наименование',
    fundingClaim: 'Заявка на оплату',
    gender: 'Пол',
    group: 'Группа',
    grossProfit: 'Валовая прибыль общая',
    hours: 'Часы',
    hoursGenitive: 'Часов',
    hoursShort: 'ч.',
    ignoreOverduePayments: 'Не учитывать просроченные оплаты',
    ignorePastAssessments: 'Не учитывать начисления прошлых месяцев',
    in: 'в',
    incomeType: 'Тип дохода',
    incomeTypeInIncomeAndOutcomeReport: 'Тип дохода в ОПИУ',
    incomes: 'Поступления',
    initialBalance: 'Начальный остаток',
    inn: 'ИНН',
    innerIncome: 'Внутреннее поступление',
    innerOutcome: 'Внутреннее списание',
    internal: 'Внутренний',
    internalPaymentClaim: 'Внутренняя заявка на оплату',
    internalTransfer: 'Внутреннее перемещение',
    issuedMultiple: 'Выдали',
    item: 'Статья',
    kindOfActivity: 'Вид деятельности',
    kpp: 'КПП',
    leader: 'Руководитель',
    left: 'Осталось',
    legalAddress: 'Юридический адрес',
    linkedDeals: 'Привязанные сделки',
    loanBody: 'Тело кредита',
    loanName: 'Название кредита (займа)',
    loanPercentage: '% по кредиту',
    loanType: 'Тип кредита (займа)',
    locality: 'Населенный пункт',
    login: 'Логин',
    logout: 'Выйти',
    mail: 'Почта',
    measure: 'Ед.изм.',
    marginalProfit: 'Маржинальная прибыль',
    month: 'Месяц',
    monthOfPayment: 'Месяц выплаты',
    monthTotal: 'Итого за месяц',
    mustPay: 'должен оплатить',
    netIncome: 'Чистая прибыль',
    name: 'Наименование',
    notShow: 'Неявки по невыясненным причинам',
    notShowAlt: 'Не явка',
    notShowShort: 'НН',
    notificationsSettings: 'Настройки уведомлений',
    number: 'Номер',
    numberAndDate: 'Номер и дата',
    numberWithDate: 'Номер/Дата',
    organization: 'Организация',
    otherAssessments: 'Прочие начисления',
    otherReduction: 'Прочие удержания',
    ourAccount: 'Наш счет',
    ourRequisite: 'Наш реквизит',
    plan: 'План',
    user: 'Пользователь',
    role: 'Роль',
    roles: 'Роли',
    roleName: 'Название роли',
    read: 'Просмотр',
    section: 'Раздел',
    subsection: 'Подраздел',
    plannedPaymentDate: 'Плановая дата оплаты',
    withoutGroup: 'Без группы',
    paymentSum: 'Сумма оплаты',
    shortDescription: 'Краткое описание',
    payerRequisite: 'Реквизит списания',
    payerAccount: 'Счет списания',
    payeeRequisite: 'Реквизит получателя',
    payeeAccount: 'Счет получателя',
    overdue: 'Просроченные',
    planned: 'Плановые',
    withoutVAT: 'Без НДС',
    professionalIncomeTax: 'НПД',
    password: 'Пароль',
    businessAccesses: 'Доступы к бизнесам',
    rightsType: 'Тип прав',
    sort: 'Сортировка',
    simple: 'Простая',
    typeInIncomeAndOutcomeReport: 'Тип в ОПИУ',
    outcomeTypeInIncomeAndOutcomeReport: 'Тип расхода в ОПИУ',
    outcomes: 'Списания',
    overview: 'Обзор',
    owner: 'Собственник',
    paid: 'Выплачено',
    paidAlt: 'Оплачено',
    paidByBody: 'Выплатили по телу',
    paidMultiple: 'Выплатили',
    paidPercent: 'Выплатили %',
    parameter: 'Параметр',
    parentAccountingItem: 'Родительская статья',
    parentDepartment: 'Родительский отдел',
    patronymic: 'Отчество',
    payment: 'Оплаты',
    paymentBefore: 'Оплата до',
    paymentMonth: 'Месяц выплаты',
    paymentPurpose: 'Назначение платежа',
    paymentSchedule: 'График оплат',
    paymentType: 'Вид выплаты',
    payments: 'Ведомости на выплаты',
    percent: 'Процент',
    percentFree: 'Беспроцентный',
    period: 'Период',
    periodWithType: 'Период/Тип',
    personalInfo: 'Личная информация',
    phone: 'Телефон',
    position: 'Должность',
    postalAddress: 'Почтовый адрес',
    preview: 'Предпросмотр',
    price: 'Цена',
    probation: 'Испытательный срок',
    productsAndJobsAndServices: 'Товары (работы, услуги)',
    profileSettings: 'Настройки аккаунта',
    profit: 'Прибыль',
    purpose: 'Назначение',
    quantity: 'Количество',
    quarter: 'Квартал',
    receivedMultiple: 'Получили',
    recipient: 'Получатель',
    recruitmentAt: 'Дата приёма',
    reductionPercent: 'Понижающий процент',
    referenceField: 'Справочное поле',
    required: 'Обязательно',
    requisite: 'Реквизит',
    requisiteType: 'Тип реквизита',
    requisites: 'Реквизиты',
    responsible: 'Ответственный',
    retentions: 'Удержания',
    returned: 'Вернули',
    returnedByBody: 'Вернули по телу',
    rootActivity: 'Главное направление',
    salaries: 'Оклады',
    selling: 'Продажа',
    sender: 'Плательщик',
    settlementAccount: 'Расчетный счет',
    settlementAccountShort: 'р/с',
    shipment: 'Отгрузки',
    shipped: 'Отгружено',
    shortName: 'Сокращенное наименование',
    showInactive: 'Показывать неактивные',
    showMore: 'Показать еще',
    site: 'Сайт',
    staffingChanges: 'Кадровые документы',
    startDebt: 'Долг на начало',
    startPeriodMoney: 'Денег на начало периода',
    status: 'Статус',
    statusType: 'Тип статуса',
    structure: 'Состав сделки',
    sum: 'Сумма',
    surname: 'Фамилия',
    syncDateFrom: 'С какой даты загружать операции',
    telegram: 'Telegram',
    thirdPerson: 'Третье лицо',
    total: 'Всего',
    totalAlt: 'Итого',
    totalDays: 'Всего дней',
    totalPayment: 'Всего к оплате',
    transactionCategory: 'Статья учета',
    transfer: 'Перевод',
    transfers: 'Переводы',
    type: 'Тип',
    unallocatedProfit: 'Нераспределённая прибыль',
    unallocated: 'Нераспределенные',
    url: 'Ссылка',
    vacation: 'Отпуск',
    vacationDays: 'Отпускных дней',
    vacationPays: 'Отпускные',
    vacationPeriod: 'Период отпуска',
    value: 'Значение',
    week: 'Неделя',
    withoutNDS: 'Без НДС',
    withoutTaxVAT: 'Без налога (НДС)',
    workingModeShort: 'Вид раб.вр',
    year: 'Год',
};
