import Vue, { ComponentOptions } from 'vue';
import { camelCase, upperFirst } from 'lodash';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/ru';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import { installPlugins } from '@/plugins';

import 'normalize.css/normalize.css';
import './styles/index.scss';

import * as uiComponents from '@/components/ui/index';

Object.keys(uiComponents).forEach(key => {
    const component = typeof uiComponents[key as keyof typeof uiComponents] !== 'function'
        ? uiComponents[key as keyof typeof uiComponents] as ComponentOptions<any>
        : (uiComponents[key as keyof typeof uiComponents] as any).options as ComponentOptions<any>;

    Vue.component(component.name || 'Ui' + upperFirst(camelCase(key)), component);
});

const svgs = import.meta.glob('@/icons/**/*.svg');
for (const path in svgs) {
    svgs[path]();
}

Vue.config.productionTip = import.meta.env.NODE_ENV === 'production';

installPlugins();

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
