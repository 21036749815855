/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    account: 'Счет',
    accountAdd: 'Новый счет',
    accountEdit: 'Редактирование счета',
    accountGroups: 'Группы счетов',
    accountingItemAdd: 'Новая учетная статья',
    accountingItemEdit: 'Редактирование учетной статьи',
    accountingItems: 'Статьи учета',
    accounts: 'Счета',
    accountsGroupAdd: 'Новая группа счетов',
    accountsGroupEdit: 'Редактирование группы счетов',
    activities: 'Направления',
    activityAdd: 'Новое направление',
    activityEdit: 'Редактирование направления',
    acts: 'Акты',
    assessmentAdd: 'Новое начисление',
    assessmentCategories: 'Начисления и удержания',
    assessmentCategoryAdd: 'Новая статья',
    assessmentCategoryEdit: 'Редактирование статьи',
    assessmentEdit: 'Редактирование начисления',
    assessmentItemAdd: 'Новое начисление/удержание',
    assessmentList: 'Список начислений',
    assessmentRules: 'Правила начислений',
    assessments: 'Начисления',
    bankCardAdd: 'Новая банковская карта',
    bankCardEdit: 'Редактирование банковской карты',
    bankCards: 'Банковские карты',
    bankRequisites: 'Банковские реквизиты',
    business: 'Бизнес',
    businessAdd: 'Новый бизнес',
    businessDelete: 'Удалить этот бизнес?',
    businessMoney: 'Деньги бизнеса',
    businessRequisites: 'Реквизиты бизнеса',
    businesses: 'Бизнесы',
    cashAccountAdd: 'Новая касса',
    cashAccountEdit: 'Редактирование кассы',
    changePlannedPaymentDate: 'Изменить плановую дату оплаты',
    claimStageAdd: 'Новый этап согласования',
    claimStageEdit: 'Редактирование этапа согласования',
    claimStageRuleAdd: 'Создание правила',
    claimStageRuleEdit: 'Редактирование правила',
    claimsAgreement: 'Согласование заявок',
    contractors: 'Контрагенты',
    contractorsList: 'Список контрагентов',
    dadata: 'DaData',
    dashboard: 'Дашборд',
    dealAdd: 'Новая сделка',
    dealEdit: 'Редактирование сделки',
    dealStatusAdd: 'Новый статус',
    dealStatusEdit: 'Редактирование статуса',
    dealStatuses: 'Статусы сделок',
    deals: 'Сделки',
    departmentAdd: 'Новый отдел',
    departmentEdit: 'Редактирование отдела',
    dividendAllocationsInBusiness: 'Распределение дивидендов в бизнесе',
    employeeAdd: 'Новый сотрудник',
    employeeEdit: 'Редактирование сотрудника',
    employees: 'Сотрудники',
    employmentAdd: 'Новый вид занятости',
    employmentEdit: 'Редактирование вида занятости',
    employments: 'Виды занятости',
    expenses: 'Затраты',
    fundingClaim: 'Заявка на оплату №{number} от {date}',
    fundingClaimAdd: 'Новая заявка на оплату',
    fundingClaimStages: 'Этапы согласования заявок на оплату',
    fundingClaims: 'Заявки на оплату',
    general: 'Общее',
    history: 'История',
    income: 'Поступление',
    innerIncome: 'Внутреннее поступление',
    innerOutcome: 'Внутреннее списание',
    integrationList: 'Список интеграций',
    integrationSettings: 'Настройка интеграций',
    integrations: 'Интеграции',
    invoiceAdd: 'Добавление счета на оплату клиенту',
    invoiceEdit: 'Редактирование счета на оплату клиенту',
    loanAdd: 'Добавление кредита/займа',
    loanEdit: 'Редактирование кредита/займа',
    loans: 'Договоры кредитов/займов',
    login: 'Вход',
    manageProfile: 'Управление аккаунтом',
    money: 'Деньги',
    moneyMovement: 'Движение денег',
    norm: 'Норма',
    notifications: 'Уведомления',
    oneSIntegrationAdd: 'Создание интеграции с 1С',
    oneSIntegrationEdit: 'Редактирование интеграции с 1С',
    operations: 'Операции',
    orgchart: 'Оргструктура',
    other: 'Другое',
    outcome: 'Списание',
    payment: 'Оплаты',
    paymentAdd: 'Новая ведомость на выплату',
    paymentCalendar: 'Платежный календарь',
    paymentEdit: 'Редактирование ведомости на выплату',
    paymentList: 'Список выплат',
    payments: 'Ведомости на выплаты',
    personalInfo: 'Личная информация',
    planning: 'Планирование',
    positionAdd: 'Новая должность',
    positionEdit: 'Редактирование должности',
    positions: 'Должности',
    products: 'Товары',
    recruitmentAdd: 'Прием на работу',
    reports: 'Отчеты',
    requisiteAdd: 'Новый реквизит',
    requisiteEdit: 'Редактирование реквизита',
    requisites: 'Реквизиты',
    retentions: 'Удержания',
    salary: 'Зарплата',
    salaryReport: 'Отчет по ЗП',
    services: 'Услуги',
    settings: 'Настройки',
    staff: 'Кадры',
    staffInfo: 'Кадровая информация',
    staffingChanges: 'Кадровые документы',
    timesheet: 'Табель',
    transfer: 'Перевод',
    upd: 'УПД',
    users: 'Пользователи',
    vacationPayAdd: 'Новое начисление отпускных',
    vacationPayEdit: 'Редактирование начисления отпускных',
    vacationPays: 'Отпускные',
    waybills: 'Накладные',
    workTimeAdd: 'Добавление рабочего времени',
    workTimeEdit: 'Редактирование рабочего времени',
    workingModeAdd: 'Новый режим работы',
    workingModeEdit: 'Редактирование режима работы',
    workingModes: 'Режимы работы',
    userAdd: 'Добавление нового пользователя',
    userEdit: 'Редактирование пользователя',
};
